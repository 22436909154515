var img_url1 = "/official/images/pc/l-logo-c6fa927f84.png";
var img_url2 = "/official/images/pc/r-logo-bff21c0c45.png";
var img_url3 =
  "https://download4.qcplay.com/qcgame-admin/iLDZRtXHF7xLBetGTDABzpPhszVonb93rY3mPyaP.png";
var img_url4 =
  "https://download4.qcplay.com/qcgame-admin/qgLjdGyMFCovN7eZKn5TrB8ivpvlRUdIvd2wXmO8.png";

// pc端
var footerHtml =
  '<div class="footer-content">' +
  '<div class="l-logo"><img src=' +
  img_url1 +
  "></div>" +
  '<div class="content">' +
  "<p>" +
  '<a target="_blank" href="https://law.supersnail.vn/vi-vn/secrecy_web.html">CHÍNH SÁCH BẢO MẬT</a>&nbsp;&nbsp;|&nbsp;&nbsp;' +
  '<a target="_blank" href="https://law.supersnail.vn/vi-vn/service_agreement_web.html">ĐIỀU KHOẢN, ĐIỀU KIỆN SỬ DỤNG DỊCH VỤ</a>&nbsp;&nbsp;|&nbsp;&nbsp;' +
  '<a target="_blank" href="https://law.supersnail.vn/vi-vn/session_management_instructions.html">QUẢN LÝ HỘI THOẠI</a>&nbsp;&nbsp;|&nbsp;&nbsp;' +
  '<a target="_blank" href="https://law.supersnail.vn/vi-vn/compensation_complaint_process.html">GIẢI QUYẾT TRANH CHẤP </a>&nbsp;&nbsp;|&nbsp;&nbsp;' +
  '<a target="_blank" href="https://law.supersnail.vn/vi-vn/privacy_policy_web.html">CHÍNH SÁCH BẢO VỆ DỮ LIỆU CÁ NHÂN </a></p>' +
  "<p>Hotline: 0878099678</p>" +
  "<p>Email: support@visionnetwork.vn</p>" +
  "<p>Bản quyền © 2024 .VISION NETWORK</p>" +
  "<p>Thời gian: 8:00 - 22:00 tất cả các ngày (GMT+7)</p>" +
  "<p>Chơi quá 180 phút một ngày sẽ ảnh hưởng xấu đến sức khỏe</p>" +
  "<p>Số 82/QĐ-BTTTT cấp ngày 29 tháng 01 năm 2024</p>" +
  "<p>Người chịu trách nhiệm nội dung: Ông Phạm Quang Minh</p>" +
  "<p>ViSION NETWORK Việt Nam, tầng 6, toà nhà Vietcom, 18 Nguyễn Chí Thanh, phường Ngọc Khánh, quận Ba Đình, Hà Nội.</p>" +
  "</div>" +
  '<div class="r-logo"><img src=' +
  img_url2 +
  "></div>" +
  "</div>";
$(".footer").append(footerHtml);

// 移动端
var mFooterHtml =
  '<div class="footer-content">' +
  '<div class="l-logo"><img src=' +
  img_url1 +
  "></div>" +
  '<div class="content">' +
  "<p>" +
  '<a target="_blank" href="https://law.supersnail.vn/vi-vn/secrecy_web.html">CHÍNH SÁCH BẢO MẬT</a>&nbsp;&nbsp;|&nbsp;&nbsp;' +
  '<a target="_blank" href="https://law.supersnail.vn/vi-vn/service_agreement_web.html ">ĐIỀU KHOẢN, ĐIỀU KIỆN SỬ DỤNG DỊCH VỤ</a>&nbsp;&nbsp;|&nbsp;&nbsp;' +
  '<a target="_blank" href="https://law.supersnail.vn/vi-vn/session_management_instructions.html">QUẢN LÝ HỘI THOẠI</a>&nbsp;&nbsp;|&nbsp;&nbsp;' +
  '<a target="_blank" href="https://law.supersnail.vn/vi-vn/compensation_complaint_process.html ">GIẢI QUYẾT TRANH CHẤP </a>&nbsp;&nbsp;|&nbsp;&nbsp;' +
  '<a target="_blank" href="https://law.supersnail.vn/vi-vn/privacy_policy_web.html">CHÍNH SÁCH BẢO VỆ DỮ LIỆU CÁ NHÂN </a></p>' +
  "<p>Hotline: 0878099678</p>" +
  "<p>Email: support@visionnetwork.vn</p>" +
  "<p>Bản quyền © 2024 .VISION NETWORK</p>" +
  "<p>Thời gian: 8:00 - 22:00 tất cả các ngày (GMT+7)</p>" +
  "<p>Chơi quá 180 phút một ngày sẽ ảnh hưởng xấu đến sức khỏe</p>" +
  "<p>Số 82/QĐ-BTTTT cấp ngày 29 tháng 01 năm 2024</p>" +
  "<p>Người chịu trách nhiệm nội dung: Ông Phạm Quang Minh</p>" +
  "<p>ViSION NETWORK Việt Nam, tầng 6, toà nhà Vietcom, 18 Nguyễn Chí Thanh, phường Ngọc Khánh, quận Ba Đình, Hà Nội.</p>" +
  "</div>" +
  '<div class="r-logo"><img src=' +
  img_url2 +
  "></div>" +
  "</div>";
$(".m-footer").append(mFooterHtml);
